.three-box-ad {
  $gap: 10px;
  &__container {
    height: fit-content;
    background-color: #DDF;
    display: grid;
    grid-template-columns: calc(100%);
    justify-content: space-between;
    gap: $gap;
    padding-left: $gap;
    padding-right: $gap;
    padding-top: $gap;
    @media (min-width: 890px) {
      // 2 slots - 1 gap divided equally between them. 
      grid-template-columns: calc(100% / 2 - $gap / 2) calc(100% / 2 - $gap / 2);
    }
    @include gt-lg-desktop {
      // 3 slots - 2 gaps divided equally between them. 
      grid-template-columns: calc(100% / 3 - 2 * $gap / 3) calc(100% / 3 - 2 * $gap / 3) calc(100% / 3 - 2 * $gap / 3);
    }
  }

  &__box {

    overflow: hidden;
    aspect-ratio: 1 / 1;
    width: 100%;
    background-color: #000;
    padding: 0;
    margin: 0;
    float: left;
    display: flex;
    flex-direction: column;
  }
  &__picture > img {
    padding: 0;
    object-fit: cover;
  }
  box__text {
    @include gt-desktop {
      font-size: 100px;
    }
  }
}

.notice-boxes {
  display: flex;
  justify-content: center;
  // width: 100vw;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
}

.three-box-ad::after {
  content: "";
  display: float;
  clear: both;
}

.three-box-ad__box > div {
  /* display: flex; */
  align-items: center;
}

.three-box-ad__box a {
  color: black;
}

.three-box-ad__last {
  /* float: right; */
}

.three-box-ad__center {
  width: 100%;
  margin: auto;
}

.fun:hover {
  background-image: linear-gradient(to right,
     rgba(200,200,200,0.2) 50%, rgba(255,255,255,0.0) 90%);
}

.bg__cool-blue {
  // background-color: rgba(55, 90, 138, 0.75);
  background-color: rgba(70, 90, 180, 0.75);
}

.bg__warm-yellow {
  //background-color: rgba(125, 100, 21 ,0.75);
  background-color: rgba(170, 100, 30 ,0.75);
}

.bg__chill-red {
  // background-color: rgba(150,70,70,0.75);
  background-color: rgba(150,70,140,0.75);
}

.image-box {
  &__image {
    padding: 0;
    margin: 0;
    display: none;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 1s;

    &.selected {
      display: block;
    }
    &.visible {
      opacity: 1;
    }
    &.pending {
      display: block;
    }
    a:hover {
      .image-box__hover-text {
        display: block;
      }
    } 
    a:focus {
      .image-box__hover-text {
        display: block;
      }
    } 
  }

  &__hover-text {
    display: none;
    position: absolute;
    > p {
      color: white;
    }
  }
}

@media (max-width: 1023px) {
  .three-box-ad__box div.box__text p {
    font-size: 14px;
  }  
  .three-box-ad__box div.box__title h2 {
    font-size: 20px;
  }  
}

@media (max-width: 1279px) {
  .three-box-ad__box div.box__text {
    padding: 10px;
    p {
      font-size: 16px;
      
    }
    li {
      font-size: 16px;
    }
  }
}

@media (min-width: 1280px) {
  .three-box-ad__box div.box__text {
    padding: 15px;
    p {
      font-size: 18px;
      
    }
    li {
      font-size: 18px;
    }
  }
}
