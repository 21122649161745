// base styles (e.g. most common tags without classes) go here

// root element


html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  min-width: 100%;
  background-color: black;
  overflow-x: hidden;
}

// Headings

h1 {
  font-family: Economica;
  font-variant: small-caps;
  hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  line-height: 1.9em;
  font-size: 24px;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 20px;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
  @include gt-phone {
    font-size: 32px;
  }
}

h2 {
  color: white;
  font-family: Economica;
  font-size: 22px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: normal;
  font-variant: small-caps;
  padding:10px;
  @include gt-phone {
    padding: 20px;
    font-size: 24px;
  }
}

h3 {
  color: white;
  font-family: Economica;
  font-size: 18px;
  padding: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: normal;
  font-variant: small-caps;
  @include gt-phone {
    padding: 20px;
    font-size: 18px;
  }
  
}

h4 {}
h5 {}
h6 {}

p {
  hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  line-height: 1.6em;
  color: black;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Atkinson Hyperlegible;
  @include gt-desktop {
    font-size: 18px;
  }
}

cite {
  font-family: Atkinson Hyperlegible;
  display: block;
  text-align: right;
}

figcaption {
  font-family: Atkinson Hyperlegible;
}

blockquote {
  font-family: Atkinson Hyperlegible;
  padding-left: 6vw;
  padding-right: 6vw;
  font-size: 14px;
  @include gt-phone {
    font-size: 16px;
  }
}

ul {
  font-family: Atkinson Hyperlegible;
  li {
    font-size: 12px;
    @include gt-phone {
      font-size: 14px;      
    }
  }
}

ol {}

a {
  font-weight: bold;
  color: #ee990f;

  &:link {
    text-decoration: none;
  }

  &:visited {
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
  }
  
  &:active {
    text-decoration: underline;
  }

  & > picture > img:hover {
    opacity: 0.5;
  }
}

a:focus {
  & > picture > img {
    opacity: 0.5; 
  }
}

img {
  padding: 10px;
}

footer {
  background-color: black;
  display: flex;
}

section {
  text-align: justify;
  // background-color: #CCCCCC;
  
  &::after {
    content: "";
    display: table;
    clear: both;
  }      
}

