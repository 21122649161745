ul.checkmark-list {

  text-overflow: ellipsis;
  list-style-position: outside;
  list-style: none;
  margin-top: 0;
  padding-left: 10px;
  padding-right: 10px;
  @include lt-phone {
    padding-left: 15px;
    padding-right: 15px;
  }

  & > li {

    &:before {
      display: inline-block;
      min-width: 30px;
      max-width: 30px;
      content: '•';    
      font-weight: bold;
      font-size: 2em;

      @include lt-phone {
      min-width: 30px;
      max-width: 30px;
      }
    }

    &.checked:before {
      content: '✓';
      color: #222244;
      //color: green;
      //text-shadow: 2px 2px 2px rgba(0,0,0,0.5);
    }

    &.checked {
      color: #222244;
    }

    & > span {
      &.event-date {
        font-weight: bold;      
      }
    }
  }
}
  
