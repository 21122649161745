.publication {
  &__cover-lift {
    display: flex;
    gap: 50px;
    align-items: center;
    justify-content: center;
    > picture {
      display: flex;
      justify-content: center;
      margin: 0;
      padding: 0;

      > img {
        width: 225px;
        margin: 0;
        padding: 0px;
        @include lt-tablet {
          width: 50vw;
        }
      }
    }

    font-style: italic;
    padding-top: 50px;
    padding-bottom: 50px; 
    @include lt-tablet {
      flex-wrap: wrap;
    }
    > blockquote {
      margin: 0;
      padding: 0;
      width: auto;
      text-align: left;
    }
  }
}
