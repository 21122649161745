.twocolumn {
    background-color: #2266aa;
    margin: 0px 0px 0px 0px;
    padding-top: 0px;
}

.twocolumn .column {
  float: left;
  width: 50%;
  min-width: 50%;
  max-width: 50%;
}

.twocolumn .column:first-child {
    background-color: #113388;    
}

/* Clear floats after the columns */
.twocolumn .row:after {
  content: "";
  display: table;
  clear: both;
}


.column_container {
    display: table;
    width: 100%;
}

.column {
  width: 50%;
  min-width: 50%;
  max-width: 50%;
  display: table-cell;
}

@media screen and (orientation: portrait) {
  .column {
      display: block;
      width: 100%;
      min-width: 100%;
      max-width: 100%;
  }
}
