.publications-details > summary::marker {
  content: '';
}

.publications-details > summary > h2::after {
  content: '+';
  padding-left: 5vw;
  align: right;
  font-size: 150%;

}

.publications-details > summary:hover {
  background-image: linear-gradient(to right,
     rgba(200,200,200,1.0) 50%, rgba(255,255,255,0.0) 90%);
  cursor: pointer;
}

details.publications-details[open] > summary > h2::after {
  content: '-';
}

.publications-details > summary > h2 {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

@keyframes details-open {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

details.publications-details[open] summary ~ * {
  animation: details-open .5s ease-in-out;
}
