// mixins-mediaquery - mixins for responsiveness
//
// - lt-{phone, tablet, desktop, lg-desktop} less than specified limit
// - gt-{phone, tablet, desktop, lg-desktop} greater than specified limit
// - A-B interval between A and B (phone, tablet, desktop, lg-desktop), A < B
//
// usage:
// @include MIXIN {
//   ...rules...
// }

$phone-width: 480px;
$tablet-width: 768px;
$desktop-width: 1024px;
$lg-desktop-width: 1440px;
$xl-desktop-width: 1920px;


@mixin portrait {
  @media (orientation: portrait) {
    @content;
  }
}

@mixin landscape {
  @media (orientation: landscape) {
    @content;
  }
}

@mixin lt-phone {
  @media (max-width: #{$phone-width - 1px}) {
    @content;
  }
}

@mixin gt-phone {
  @media (min-width: #{$phone-width}) {
    @content;
  }
}

  
@mixin lt-tablet {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin gt-tablet {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin lt-desktop {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin gt-desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin lt-lg-desktop {
  @media (max-width: #{$lg-desktop-width - 1px}) {
    @content;
  }
}

@mixin gt-lg-desktop {
  @media (min-width: #{$lg-desktop-width}) {
    @content;
  }
}

@mixin gt-xl-desktop {
  @media (min-width: #{$xl-desktop-width}) {
    @content;
  }
}
  
@mixin phone-tablet {
  @media (min-width: #{$phone-width}) and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin phone-desktop {
  @media (min-width: #{$phone-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin phone-lg-desktop {
  @media (min-width: #{$phone-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin tablet-desktop {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin tablet-lg-desktop {
  @media (min-width: #{$tablet-width}) and (max-width: #{$lg-desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop-lg-desktop {
  @media (min-width: #{$desktop-width}) and (max-width: #{$lg-desktop-width - 1px}) {
    @content;
  }
}
