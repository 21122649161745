
.main_page {
  background-color: white;
  padding-bottom: 50px;
  width: 100%;
  height: 100%;
}

.main_page li {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 25px;
}



