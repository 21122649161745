/* atkinson-hyperlegible-regular - latin */
@font-face {
  font-family: 'Atkinson Hyperlegible';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/atkinson-hyperlegible-v9-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/atkinson-hyperlegible-v9-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/atkinson-hyperlegible-v9-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/atkinson-hyperlegible-v9-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/atkinson-hyperlegible-v9-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/atkinson-hyperlegible-v9-latin-regular.svg#AtkinsonHyperlegible') format('svg'); /* Legacy iOS */
}

/* archivo-narrow-regular - latin */
@font-face {
  font-family: 'Archivo Narrow';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/archivo-narrow-v22-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/archivo-narrow-v22-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/archivo-narrow-v22-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/archivo-narrow-v22-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/archivo-narrow-v22-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/archivo-narrow-v22-latin-regular.svg#ArchivoNarrow') format('svg'); /* Legacy iOS */
}

/* audiowide-regular - latin */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/audiowide-v16-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/audiowide-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/audiowide-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/audiowide-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/audiowide-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/audiowide-v16-latin-regular.svg#Audiowide') format('svg'); /* Legacy iOS */
}

/* economica-regular - latin */
@font-face {
  font-family: 'Economica';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/economica-v13-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/economica-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/economica-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/economica-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/economica-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/economica-v13-latin-regular.svg#Economica') format('svg'); /* Legacy iOS */
}
