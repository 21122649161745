header {
  position: relative;
  min-width: 100%;
  z-index: 999;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: black;    
  display: flex;
  justify-content: space-between;
  min-height: 100px;
  max-height: 100px;
  @include gt-desktop {
    min-height: 150px;
    max-height: 150px;
    position: relative;
  }

  .mainlogo-container {
    svg {
      width: 80px;
      @include gt-desktop {
        width: 100px;
      }
    }
  }
  
  .header_elem {
    width: 100%;
    @media screen and (min-width: 768px) {
      width: auto;
    }
  }
  
  #menu_mobile {
    display: block;
    padding-left: 0;
    padding-bottom: 0;
    padding-top: 10px;
    padding-right: 10px;
    
    @media screen and (min-width: 768px) {
      display: none;
    }
  }
  
  #menu {
    display: none;
    position: absolute;
    background-color: rgba(0,0,0,0.85);
    width: 100%;
    top: 90px;
    color: white;
    font-weight: normal;
    font-variant: small-caps;
    margin-bottom: 0px;
    font-family: Atkinson Hyperlegible;
    @media screen and (min-width: 768px) {
      width: 70%;
      display: block;
      position: static;
    }
    &.open {
      display: block;
      > nav.nav-menu {
        overflow-y: scroll;
        max-height: calc(100vh - 90px);
        @include gt-tablet {
          overflow-y: visible;
          max-height: none;
        }
      }
    }

    .dropdown > div {
      position: static;
      border: solid 1px black;
      width: 100%;
      height: 100%;
      border-radius: 0px;
      padding: 0;
      @media screen and (min-width: 768px) {
        position: absolute;
      }    
    }  
  }
  
  .title {
    font-family: Economica;
    font-variant: small-caps;
    hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    line-height: 1.9em;
    font-size: 24px;
    padding-left: 2vw;
    padding-right: 2vw;
    margin-top: 0;
    padding-top: 0px;
    padding-bottom: 0px;
    color: white;
    font-size: 24px;
    font-weight: normal;
    font-variant: small-caps;
    margin-bottom: 0;
    white-space: nowrap;
    
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      font-size: 24px;
    }
    @media screen and (min-width: 1024px) and (max-width: 1439px) {
      font-size: 32px;
    }
    @media screen and (min-width: 1440px) {
      font-size: 48px;
    }
  }
  
  .subtitle {
    color: white;
    font-size: 14px;
    font-variant: small-caps;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
    @media screen and (min-width: 1024px) and (max-width: 1439px) {
      font-size: 20px;
    }
    @media screen and (min-width: 1440px) {
      font-size: 28px;
    }
  }

  .nav-menu {
    color: white;

    ul {
      list-style: none;
    }

    > ul {
      margin: 0;
      position: right;
      padding-left: 0px;
      padding-right: 0px;
    
      > li {

        padding: 0px;
        float: none;
        display: block;
        border-top: solid 1px white;
        border-bottom: solid 1px white;
        min-height: auto;
        min-width: 90%;
        margin: auto;
        line-height: 50px;
        text-align: center;
        padding-left: 0.05vw;
        padding-right: 0.05vw;
        font-size: 20px;

        @include gt-tablet {
          font-size: 18px;
          border-top: none;
          border-bottom: none;
          min-width: auto;
          display: inline-block;
          padding-left: 2px;
          padding-right: 2px;
        }

        > span {
          line-height: 68px;
          width: 8vw;
          text-align: center;
          display: inline-block;
        }
      }
    }
    & details {
      padding-left: 0px;
      padding-right: 0px;
      width: 100%;
      @media (min-width: 768px) {
        width: 15vw;
      }

      > div {
        position: absolute;
        padding-top: 10px;
        padding-bottom: 10px;
        top: 43px;
        left: 0px;
        width: 15vw;
      }

      summary {
        color: white;
        font-weight: bold;
        padding-top: 0;
        padding-bottom: 0;
        text-align: center;
        margin-bottom: 6px;
      }
      &[open] {
        background: linear-gradient(to left, #AAA 0%, #FFF 20%, #FFF 80%, #AAA 100%);
      }
    }
    

    & details[open] > summary {
      color: black;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .subnav {
    z-index: 999;
    margin-top: 11px;
    
    > ul {
      width: 100%;
      padding: 0px;
      background: linear-gradient(to left, #555 0%, #666 20%, #666 80%, #555 100%);
      
      > li {
        text-align: center;
        padding-top: 0;
        padding-bottom: 0;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        border: solid 1px black;
        width: 100%;
      }
    }
  }
}

nav a:hover {
  color: #FF900C;
}

nav summary {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
}

nav summary:hover {
  color: #FF900C;
}

header a:hover p {
  color: #FF900C;
}


/* Animation */

@keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
    opacity: 0
}

.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}

.header_elem {
    margin-top: 10px;
    margin-bottom: 0px;    
    padding-top: 0px;
    padding-bottom: 0px;
    flex: 1;
    margin-left: 10px;
    margin-right: 0px;
}

.header_elem:nth-of-type(2) {
  margin-top: 10px;
  margin-left: 0px;
  margin-right: 0px;
}

.header_elem:first-child {
    flex: 0.1;
    /* align-items: left;*/
}

.header_elem img {
    float: right;
    padding-top: 10px;
}

.main_heading {
    min-width: 270px;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
}


a:hover h1 {
  color: #FF900C;
}


  
@media screen and (orientation: portrait) {


  /* div.spacer { */
  /*   display: inline-block; */
  /*   height: 142px; */
  /*   width: 100%; */
  /* } */


} 

.some {
    top: 10px;
  position: relative;
  display: inline-block;
}

.dropbtn {
  background-color: black;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown > summary:hover {
  color: #FF900C;
}

.dropdown-content a:hover {background-color: #ddd;}

.dropdown:hover .dropdown-content {display: block;}

.dropdown:hover .dropbtn {background-color: #3e8e41;}

.headertext {
  top: 50%;
  left: 50%;
  color: white;
  width: 100%;
}

svg {
  vertical-align: middle;
}
svg:hover > path {
  fill: #FF900C;
}

.nav-menu a {
  color: white;
}

.cont-icon {
  padding-left: 10px;
  padding-right: 10px;
}

a.cont-icon:link {
  text-decoration: none;
}

a.cont-icon:visited {
  text-decoration: none;
}

a.cont-icon:hover {
  text-decoration: underline;
}

a.cont-icon:active {
  text-decoration: underline;
}

button.mobile-menu-toggler {
  padding: 0;
  border: none;
  background: none;
  font-size: 48px;
  color: white;
  background-color: black;
  &:hover {
    cursor: pointer;
    text-decoration: none;
    color: #FF900C;
    
  }
  &:active {
    text-decoration: none; 
    color: #FF900C;
  }
}

/* @media (min-width: 480px) and (max-width: 1200px) { */
/*   .title { */
/*     font-size: 1.6vw; */
/*   } */
/*   .subtitle { */
/*     font-size: 1.4vw; */
/*   } */
/*   .nav-menu > ul > li { */
/*     padding-left: 0.04vw; */
/*     padding-right: 0.04vw; */
/*     font-size: 2.0vw; */
/*   } */
/* } */

details.dropdown[open] > *:not(summary) {
  animation: nav-menu-show-fadein 200ms ease-in-out;
}

@media (max-width: 768px) {
  details.dropdown[open] > *:not(summary) {
    animation: nav-menu-show-fadein 400ms ease-in-out;
  }
  /* .title { */
  /*   font-size: 3.0vw; */
  /* } */
  /* .subtitle { */
  /*   font-size: 2.5vw; */
  /* } */
}

/* @media (min-width: 1201px) and (max-width: 1439px) { */
/*   .title { */
/*     font-size: 1.5vw; */
/*   } */
/*   .subtitle { */
/*     font-size: 1.3vw; */
/*   } */
/*   .nav-menu > ul > li { */
/*     padding-left: 15px; */
/*     padding-right: 15px; */
/*     font-size: 1.3vw; */
/*   } */
/* } */

@media (min-width: 1440px) {
  .nav-menu > ul > li {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1.3vw;
  }
}

@keyframes nav-menu-show-sideways {
  from {
    opacity: 0%;
    margin-left: 100%;
    width: 300%;
  }

  to {
    opacity: 100%;
    margin-left: 0%;
    width: 100%;
  }
}

@keyframes nav-menu-show-fadein {
  from {
    opacity: 0%;
  }

  to {
    opacity: 100%;
  }
}

.unscrollable-in-mobile {
  overflow-y: hidden;
  @include gt-tablet {
    overflow-y: visible;
  }
}
