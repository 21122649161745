.events-images {
  display: flex;
  width: 100%;
  height: auto;
  :not(:first-child) {
    @include lt-tablet {
      display: none;
    }
  }
  @include lt-tablet {
    flex-direction: column;
  }
}

.events-image {
  object-fit: cover;
  border-radius: 0;
  width: calc(100% / 3);
  height: 20rem;
  @include lt-tablet {
    width: 100%;
  }
}
