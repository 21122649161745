footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 100%;
}

.footer-elem-left {
  padding-left: 10vw;
  float: left;
  height: 100px;
  display: flex;
  align-items: center;
}

.footer-elem-left p {
  margin: 0;
  padding: 0;
  color: white;
}

.footer-elem-right {
  padding-right: 10vw;
  float: right;
  height: 100px;
  display: inline-block;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.footer-elem-left p {
  color: white;
}
