.hero {
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2)), url("../img/syntykeho.webp");
  min-width: 100vw;
  min-height: 25vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  @media screen and (orientation: portrait) {
    height: 100vw;
  }
  &__section {
    background-color: black;
  }
}

.hero-mkp {
  background-image: url("../img/mkp.webp");
}

.hero__content {
  min-height: 100%;
  padding-left: 10vw;
  padding-right: 10vw;
  padding-top: 10%;
}
.hero__title {
  font-variant: small-caps;
  font-family: "Audiowide", sans-serif;
  font-size: 2em;
  color: white;
  text-align: left;
  text-shadow: 2px 2px 4px #000000;
  @media screen and (orientation: portrait) {
  font-size: 1.5em;
  text-align: right;
  text-wrap: balance;
  }
}

.hero__title a {
  color: white;
  @media screen and (orientation: portrait) {
    top: 100px;
  }
}

.hero__subtitle {
  font-variant: small-caps;
  font-family: "Audiowide", sans-serif;
  font-size: 1em;
  color: white;
  line-height: 1em;
  text-shadow: 2px 2px 4px #000000;
  @media screen and (orientation: portrait) {
    font-size: 0.75em;
    text-align: right;
  }
}


.hero__date {
  padding-top: 10vw;
  padding-bottom: 10vw;
  font-variant: small-caps;
  font-family: 'Archivo Narrow';font-size: 22px;
  font-size: 1.1em;
  color: white;
  text-align: right;
  text-shadow: 2px 2px 4px #000000;
  
  a {
    color: white;
  } 
}
/*

}
*/
