.introduction {
  
  &__image {
    width: 100%;
    border-radius: 0px;
    padding: 0;
    @include gt-phone {
    width: 50%;
    border-radius: 50px;
    }
  }  
}
