html,body {
}

.headerheight {
    height: 50vh;
}

.clearfix {
    clear: both;
}

.parallax {
    position: relative;
    background-attachment: fixed;
    background-position: center 0;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
}

.kohi {
    background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1.0)), url("../img/kohi.png");
}

.nosto {
    font-style: italic;
    /* top: 20px; */
    /* position: fixed; */
    padding-top: 50px;
    padding-bottom: 50px; 
  overflow: hidden;
   display: flex;
   align-items: center;
   @include lt-tablet {
     flex-wrap: wrap;
   }
   > blockquote {
     text-align: left;
   }
}


#bg {
    padding-top: 0px;
    margin-top: 0px;
    padding-top: 0px;
    background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url("../img/bg.jpg");
}    


/* Center website */
.indent {
  max-width: 1000px;
  margin: auto;
  padding-left: 5vw;
  padding-right: 5vw;
}

.center_vert {
    align-items: center;
}

.collapsible {
    background-color: white;
    color: black;
    cursor: pointer;
    padding: 18px;
    margin-bottom: 3vw;
    margin-top: 3vw;

    width: 100%;
    border: dashed;
    font-family: courier;
    text-align: left;
    outline: none;
    font-size: calc(3mm + 1vw);
    font-weight: bold;
}

.active, .collapsible:hover {
    background-color: #fffcd9;    
}

.content {
    padding: 0 4vw;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    background-color: white;
    font-family: helvetica;

}

.right_float {
    max-width: 30%;
    float:right;
}

@media screen and (orientation: portrait) {
    .right_float {
	max-width: 100%;
    }
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.star {
    visibility: hidden;
}

.fadeIn {
    -webkit-animation: animat_show 0.8s;
    animation: animat_show 0.8s;
    visibility: visible !important;
}

@-webkit-keyframes animat_show{
    0%{opacity:0}
    100%{opacity:1}
}

.fade-in {
  animation: fadeIn 2s;
  -webkit-animation: fadeIn 2s;
  -moz-animation: fadeIn 2s;
  -o-animation: fadeIn 2s;
  -ms-animation: fadeIn 2s;
}

.fade-in-delay {
  animation: fadeInDelay 4s;
  -webkit-animation: fadeInDelay 4s;
  -moz-animation: fadeInDelay 4s;
  -o-animation: fadeInDelay 4s;
  -ms-animation: fadeInDelay 4s;
}

@keyframes fadeIn {
  0% {opacity:0;filter: blur(8px);
  -webkit-filter: blur(8px);}
  100% {opacity:1;filter: blur(0px);
  -webkit-filter: blur(0px);}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;filter: blur(8px);
  -webkit-filter: blur(8px);}
  100% {opacity:1;filter: blur(0px);
  -webkit-filter: blur(0px);}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;filter: blur(8px);
  -webkit-filter: blur(8px);}
  100% {opacity:1;filter: blur(0px);
  -webkit-filter: blur(0px);}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@keyframes fadeInDelay {
  0% {opacity:0;}
  60% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeInDelay {
  0% {opacity:0;}
  60% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeInDelay {
  0% {opacity:0;}
  60% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeInDelay {
  0% {opacity:0;}
  60% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeInDelay {
  0% {opacity:0;}
  60% {opacity:0;}
  100% {opacity:1;}
}

.all-white {
  background-color: white;
}

.all-white p {
  color: black;
}

.all-white h1 {
  color: black;
}

.all-white h2 {
  color: black;
}

.all-white h3 {
  color: black;
}

.notfound-image img {
  max-width: 100%;
  border-radius: 50%;
}

.notfound-image figcaption {
  font-size: 1.5vw;
  font-weight: bold;
  font-style: italic;
}

.drop-shadow {
  filter: drop-shadow(0px 0px 20px black);
}

.rounded {
  border-radius: 0px;
  @include gt-phone {
    border-radius: 50px;
  }
}


.inverted-links a {
  color: rgb(60,60,220);
}

.attached {
  width: 100vw;
}

.padded {
  padding: 30px;
}

.container {
  padding-top: 90px;
  @include gt-desktop {
    padding-top: 0px;
  }
}
